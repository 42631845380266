import React from 'react'

const ModuleIconTools: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_27696_8723)">
        <rect width="62" height="62" fill="url(#paint0_linear_27696_8723)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.637 13.893C37.9629 13.893 39.6123 15.1649 40.5945 17.6417L49.1288 12.7551H49.1844L41.9103 20.0611C41.0106 19.7046 40.4034 19.5278 39.3218 19.2129C39.1559 19.1645 38.9787 19.1129 38.7876 19.057C36.4617 18.3971 34.9142 17.8903 34.0895 17.4887C33.3204 17.087 32.9034 16.5228 32.9034 15.7674C32.9034 14.7059 33.8301 13.893 35.637 13.893ZM22.6374 15.8133C22.6374 15.294 22.665 14.7841 22.7294 14.2932L12.8359 49.285L39.6817 41.0233C38.5221 41.2121 37.2889 41.3066 35.9728 41.3066C28.1777 41.3066 22.7938 37.9075 20.9992 32.5067L29.7698 27.5591C30.9479 30.505 33.1014 32.0062 36.2305 32.0062C38.5405 32.0062 39.7185 31.402 39.7185 30.2028C39.7185 29.5608 39.4608 29.0037 38.4853 28.6071C38.0251 28.3994 37.565 28.2578 37.206 28.1539C36.9377 28.041 36.5254 27.9281 35.9692 27.7757C35.7813 27.7242 35.5769 27.6683 35.3562 27.6063L33.3591 27.0587C26.2266 24.9626 22.6374 21.2141 22.6374 15.8133Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_27696_8723"
          x1="51.716"
          y1="-21.8273"
          x2="-20.102"
          y2="6.33297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D4E67" />
          <stop offset="1" stopColor="#112538" />
        </linearGradient>
        <clipPath id="clip0_27696_8723">
          <rect width="62" height="62" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ModuleIconTools
